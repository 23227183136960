import localforage from 'localforage';
var slugify = require('slugify');
import dayjs from 'dayjs'
//UUID
function generateUUIDv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const getUUID = async () => {
    var uuidv4 = await localforage.getItem('uuidv4');
    if (uuidv4 && uuidv4.length > 1) {
        return uuidv4;
    }
    uuidv4 = generateUUIDv4();
    localforage.setItem('uuidv4', uuidv4);
    return uuidv4;
}

//Slugify Content
export function slugifyContent(item = '') {
    if (item) {
        item = item.replace(/[-]/g, "");
    }

    return slugify(item, { remove: /[*+-?~.()'"–!:@]/g, lower: true });
    // return slugify(item, { strict: true, lower: true });
}

export function escapeJson(data) {
    // var jsonString = JSON.stringify(data);
    // console.log('Raw data ==>> ' + data);
    // console.log('Stringified JSON ==>> ' + jsonString);
    // const jsStringLiteral = JSON.stringify(json);
    // return JSON.parse(json);
    var escapedData = data.replace(/\n/g, '\\n')
        // .replace(/'/g, "\\'")
        .replace(/"/g, '\\"')
        .replace(/\\/, "");
    // .replace(/&/g, "\\&");
    // .replace(/\\r/g, "\\r")
    // .replace(/\\t/g, "\\t")
    // .replace(/\\b/g, "\\b")
    // .replace(/\\f/g, "\\f");
    // return jsesc(data, { 'quotes': 'double' });
    // var escapedData = escapeJSON(data);
    // console.log('Escaped JSON ==>> ' + escapedData);
    return escapedData;
}

// /locale/month/date

export function currentMonthDate() {
    const time = dayjs();
    const month = time.format('M');
    const monthsArray = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    let date = time.format('D');
    if (date < 10) {
        date = '0' + date;
    }
    const locale = 'en';
    return { locale, date, monthsArray, month }
}

export function isAmpDetailPage(page) {
    if (page) {
        return page.includes("/amp")
    } else {
        return false;
    }


}

export function restructureDashboardJsonWithStrapi(json) {
    // console.log("Initial JSON ==>> " + JSON.stringify(json));
    // const arr = JSON.parse(json);
    json.forEach(obj => renameKeys(obj));
    // renameKeys(json);
    // const updatedJson = JSON.stringify(arr);
    // const updatedJson = JSON.stringify(json);
    // console.log("Updated JSON ==>> " + updatedJson);
}

export function renameKeys(obj) {
    // console.log("Json object ==>> " + JSON.stringify(obj));
    try {

        obj['event_title'] = obj['title'];
        delete obj['title'];
        obj['event_summary'] = obj['summary'];
        delete obj['summary'];
        obj['event_body'] = obj['body_components'];
        delete obj['body_components'];

        obj['published_at'] = obj['published_at'];
        obj['created_at'] = obj['published_at'];
        obj['updated_at'] = obj['published_at'];

        obj['months'] = {
            "month": "October"
        };

        obj['day'] = {
            "days": 2
        }

        obj['event_types'] = [
            {
                "id": 3,
                "Title": "Event",
                "published_at": "2021-01-22T13:46:14.816Z",
                "created_at": "2021-01-22T13:46:13.441Z",
                "updated_at": "2021-01-22T13:46:14.857Z"
            }
        ];

        obj['author'] = {
            "name": obj['author']['name'],
            "about": obj['author']['about'],
            // "published_at": "2022-05-13T06:27:17.905Z",
            // "created_at": "2022-05-13T06:27:14.660Z",
            // "updated_at": "2022-05-13T06:27:17.929Z",
            "profile": {
                "id": 7115,
                "name": "WhatsApp Image 2021-09-28 at 10.30.31 PM.jpeg",
                "alternativeText": "",
                "caption": "",
                "width": 1440,
                "height": 1080,
                "formats": {
                    "thumbnail": {
                        "name": "thumbnail_WhatsApp Image 2021-09-28 at 10.30.31 PM.jpeg",
                        "hash": "thumbnail_Whats_App_Image_2021_09_28_at_10_30_31_PM_ab3ea59f98",
                        "ext": ".jpeg",
                        "mime": "image/jpeg",
                        "width": 208,
                        "height": 156,
                        "size": 9.11,
                        "path": null,
                        "url": obj['author']?.picture
                    },
                    "large": {
                        "name": "large_WhatsApp Image 2021-09-28 at 10.30.31 PM.jpeg",
                        "hash": "large_Whats_App_Image_2021_09_28_at_10_30_31_PM_ab3ea59f98",
                        "ext": ".jpeg",
                        "mime": "image/jpeg",
                        "width": 1000,
                        "height": 750,
                        "size": 182.24,
                        "path": null,
                        "url": obj['author']?.picture
                    },
                    "medium": {
                        "name": "medium_WhatsApp Image 2021-09-28 at 10.30.31 PM.jpeg",
                        "hash": "medium_Whats_App_Image_2021_09_28_at_10_30_31_PM_ab3ea59f98",
                        "ext": ".jpeg",
                        "mime": "image/jpeg",
                        "width": 750,
                        "height": 563,
                        "size": 104.72,
                        "path": null,
                        "url": obj['author']?.picture
                    },
                    "small": {
                        "name": "small_WhatsApp Image 2021-09-28 at 10.30.31 PM.jpeg",
                        "hash": "small_Whats_App_Image_2021_09_28_at_10_30_31_PM_ab3ea59f98",
                        "ext": ".jpeg",
                        "mime": "image/jpeg",
                        "width": 500,
                        "height": 375,
                        "size": 46.72,
                        "path": null,
                        "url": obj['author']?.picture
                    }
                },
            }
        }

        obj['cover_image'] = {
            "id": 6421,
            "name": "Untitled (19).jpg",
            "alternativeText": (obj['cover_image']?.alternative_text ? obj['cover_image']?.alternative_text : " "),
            "caption": (obj['cover_image'] ? (obj['cover_image'].caption ? obj['cover_image'].caption : " ") : " "),
            "width": obj['cover_image'] ? obj['cover_image'].width : 0,
            "height": obj['cover_image'] ? obj['cover_image'].height : 0,
            "formats": {
                "thumbnail": {
                    "name": "thumbnail_Untitled (19).jpg",
                    "hash": "thumbnail_Untitled_19_64ccd9e155",
                    "ext": ".jpg",
                    "mime": "image/jpeg",
                    "width": 245,
                    "height": 128,
                    "size": 11.59,
                    "path": null,
                    "url": obj['cover_image'] ? obj['cover_image'].url : " ",
                },
                "medium": {
                    "name": "medium_Untitled (19).jpg",
                    "hash": "medium_Untitled_19_64ccd9e155",
                    "ext": ".jpg",
                    "mime": "image/jpeg",
                    "width": 750,
                    "height": 392,
                    "size": 73.3,
                    "path": null,
                    "url": obj['cover_image'] ? obj['cover_image'].url : " ",
                },
                "small": {
                    "name": "small_Untitled (19).jpg",
                    "hash": "small_Untitled_19_64ccd9e155",
                    "ext": ".jpg",
                    "mime": "image/jpeg",
                    "width": 500,
                    "height": 262,
                    "size": 38.62,
                    "path": null,
                    "url": obj['cover_image'] ? obj['cover_image'].url : " ",
                }
            },
            "hash": "Untitled_19_64ccd9e155",
            "ext": ".jpg",
            "mime": "image/jpeg",
            "size": 117.37,
            "url": obj['cover_image'] ? obj['cover_image'].url : " ",
            "previewUrl": null,
            "provider": "local",
            "provider_metadata": null,
            "created_at": "2022-01-01T14:30:27.673Z",
            "updated_at": "2022-01-01T14:30:27.710Z"
        }

        obj['event_body'].forEach(obj => updateEventBody(obj));
        obj['event_niches'].forEach(obj => updateEventNiche(obj));

        // console.log("Json object ==>> " + JSON.stringify(obj));


    } catch (error) {
        console.log("Error Error ==>> " + error + obj['event_title'])
    }
}
export function updateEventNiche(obj) {
    obj['niche'] = obj['title'];
}
export function updateEventBody(obj) {
    if (obj['component_type'] === "text") {
        obj['__component'] = "rich-text.rich-text";
        delete obj['component_type'];
        obj['body_text'] = obj['text_final'];
        delete obj['text_final'];
    }
    if (obj['component_type'] === "image") {
        obj['__component'] = "images.image";
        delete obj['component_type'];
        obj['image'] = [
            {
                "id": obj['image']?.id,
                "name": obj['image'] ? obj['image'].name : " ",
                "alternativeText": (obj['image']?.alternative_text ? obj['image']?.alternative_text : " "),
                "caption": (obj['image'] ? (obj['image'].caption ? obj['image'].caption : " ") : " "),
                "width": obj['image'] ? obj['image'].width : 0,
                "height": obj['image'] ? obj['image'].height : 0,
                "formats": {
                    "thumbnail": {
                        "name": "thumbnail_Rajendra_Prasad_readies_to_take_part_in_the_first_Republic_Day_parade_67ab43db8f.jpeg",
                        "hash": "thumbnail_Rajendra_Prasad_readies_to_take_part_in_the_first_Republic_Day_parade_67ab43db8f_5f8aa03ad9",
                        "ext": ".jpeg",
                        "mime": "image/jpeg",
                        "width": 208,
                        "height": 156,
                        "size": 12.63,
                        "path": null,
                        "url": obj['image'] ? obj['image'].url : " ",
                    },
                    "medium": {
                        "name": "medium_Rajendra_Prasad_readies_to_take_part_in_the_first_Republic_Day_parade_67ab43db8f.jpeg",
                        "hash": "medium_Rajendra_Prasad_readies_to_take_part_in_the_first_Republic_Day_parade_67ab43db8f_5f8aa03ad9",
                        "ext": ".jpeg",
                        "mime": "image/jpeg",
                        "width": 750,
                        "height": 563,
                        "size": 122.91,
                        "path": null,
                        "url": obj['image'] ? obj['image'].url : " ",
                    },
                    "small": {
                        "name": "small_Rajendra_Prasad_readies_to_take_part_in_the_first_Republic_Day_parade_67ab43db8f.jpeg",
                        "hash": "small_Rajendra_Prasad_readies_to_take_part_in_the_first_Republic_Day_parade_67ab43db8f_5f8aa03ad9",
                        "ext": ".jpeg",
                        "mime": "image/jpeg",
                        "width": 500,
                        "height": 375,
                        "size": 64.27,
                        "path": null,
                        "url": obj['image'] ? obj['image'].url : " ",
                    }
                },
                "hash": "Rajendra_Prasad_readies_to_take_part_in_the_first_Republic_Day_parade_67ab43db8f_5f8aa03ad9",
                "ext": ".jpeg",
                "mime": "image/jpeg",
                "size": 93.46,
                "url": obj['image'] ? obj['image'].url : " ",
                "previewUrl": null,
                "provider": "local",
                "provider_metadata": null,
                "created_at": "2022-01-26T02:47:29.231Z",
                "updated_at": "2022-01-26T02:47:29.271Z"
            }
        ]
    }
}

export function updateEventType(obj) {
    obj['Title'] = obj['title'];
    delete obj['title'];

}

export function getMonthNameFromDate(date) {
    const eventMonth = dayjs(date).month();
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[eventMonth];
}

export function getDayFromDate(date) {
    const eventDay = dayjs(date).day();
    return eventDay;
}

export function randomizeEvents(content) {
    for (let i = content.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        if (content[i]?.featured || content[j]?.featured) {
            continue;
        }
        const temp = content[i];
        content[i] = content[j];
        content[j] = temp;
    }
}