import React from 'react'
import styled,{keyframes} from 'styled-components';
const loader = keyframes`
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
`
const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
.loader {
border: 4px solid #AEAEAE;
border-radius: 50%;
border-top: 4px solid #525252;
width: 20px;
height: 20px;
-webkit-animation: ${loader} 2s linear infinite;
animation:${loader} 2s linear infinite;
}
h4{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin-left: 5px;
}
`
const PageLoading = () => {
    return (
        <Container>
            <div className="loader"></div>
            <h4>Fetching next story</h4>
      </Container>
    )
}

export default PageLoading
