import React from 'react'
const Shimmer = () => {
    return (
        <section className="shimmer-section">
        {/* <div className="nav">
            <div className="nav__logo shine "></div>
            <div className="nav__category shine"></div>
        </div> */}
        <div className="featured-event">
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
         <div className="shine featured-event__circle"></div>
        </div>
        <div className="main-event">
            <div className="shine main-event__card"></div>
        </div>
        <div className="other-event">
            <div className="other-event__card"><div className="shine other-event__card-img"></div><div className="other-event__card-body"><div className="shine"></div>
            <div className="shine"></div>
            <div className="shine"></div></div></div>
            <div className="other-event__card"><div className="shine other-event__card-img"></div><div className="other-event__card-body"><div className="shine"></div>
            <div className="shine"></div>
            <div className="shine"></div></div></div>
            <div className="other-event__card"><div className="shine other-event__card-img"></div><div className="other-event__card-body"><div className="shine"></div>
            <div className="shine"></div>
            <div className="shine"></div></div></div>
            <div className="other-event__card"><div className="shine other-event__card-img"></div><div className="other-event__card-body"><div className="shine"></div>
            <div className="shine"></div>
            <div className="shine"></div></div></div>
            <div className="other-event__card"><div className="shine other-event__card-img"></div><div className="other-event__card-body"><div className="shine"></div>
            <div className="shine"></div>
            <div className="shine"></div></div></div>
            <div className="other-event__card"><div className="shine other-event__card-img"></div><div className="other-event__card-body"><div className="shine"></div>
            <div className="shine"></div>
            <div className="shine"></div></div></div>
            <div className="other-event__card"><div className="shine other-event__card-img"></div><div className="other-event__card-body"><div className="shine"></div>
            <div className="shine"></div>
            <div className="shine"></div></div></div>
        </div>
    </section>
    )
}

export default Shimmer
