import styled from "styled-components";

const FooterContainer = styled.footer`
  background-color: rgb(255, 255, 255);
  padding-bottom: 20px;
  max-width: 600px;
  border-top: .5px solid rgba(0,0,0,.12);
  font-family: "Montserrat", sans-serif;
  flex: 1;
flex-basis: 100%;
  .footer-item {
    a {
      text-decoration: none;
      border-bottom: none;
    }
    a p {
      text-decoration-line: underline;
    }
  }
  .footer-item:first-child {
    display: flex;
    margin-top: 20px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }
  .footer-item:nth-child(2) {
    height: auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 50px;
    font-size: 12px;
     line-height: 24px;
  }
  .footer-item:nth-child(3) {
    height: auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 50px;
    font-size: 12px;
     line-height: 24px;
  }
  .instalogo {
    display: flex;
    margin-top: 20px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }
`;
const Footer = () => {
  return (
    <>
    <FooterContainer>
      <div className="footer-item">
        <amp-img
          src="/icons/thisday-gray.svg"
          layout="fixed"
          width="73"
          height="23"
        />
      </div>
      <div className="footer-item">
        <a href="https://angel.co/company/ekank-technologies">
          <p>About Us</p>
        </a>
        <a href="mailto:feedback@ekank.com">
          <p>Contact Us</p>
        </a>
        <a href="https://angel.co/company/ekank-technologies">
          <p>Careers</p>
        </a>
      </div>
      <div className="footer-item">
      <a href={`${process.env.ENV_IMAGE_ROOT}/terms-of-service.html`}>
              <p>
                Terms of Service
              </p>
      </a>
     <a href={`${process.env.ENV_IMAGE_ROOT}/privacy-policy.html`}>
              <p>Privacy Policy</p>
       </a>
      </div>
      <div className="footer-item">
        <p style={{textAlign : 'center',fontWeight : '600',fontSize : '13px'}}>© 2021 Ekank Technologies Private Limited</p>
      </div>
      <div className="footer-item">
        <div className="instalogo">
          <a href="https://www.instagram.com/thisday.app/">
            <amp-img
              src="/icons/instagram.svg"
              height="37"
              width="110"
              layout="fixed"
            />
          </a>
        </div>
      </div>
    </FooterContainer>
    </>
  );
};

export default Footer;
